<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems" />
    <div class="custom-search d-flex justify-content-between mb-1">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="false"
          :rows="modifiedApiKeys"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            trigger: 'enter',
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->
            <div v-if="props.column.field === 'client_id'" class="px-auto">
              <span>{{ truncateString(props.row.client_id, 50) }}</span>
            </div>
            <span v-else-if="props.column.field === 'creationDate'">
              {{ props.row.creation_date.date }}
            </span>
            <span v-else-if="props.column.field === 'status'">
              {{ props.row.status ? $t("Active") : $t("Inactive") }}
            </span>
            <span v-else-if="props.column.field === 'roles'">
              <multiselect
                v-model="props.row.roles"
                label="title"
                @select="assignRoles($event, props.row.roles, props.row.id)"
                @remove="removeRoles($event, props.row.roles, props.row.id)"
                track-by="id"
                :options="roles"
                :multiple="true"
              >
              </multiselect>
            </span>

            <!-- Column: Action -->
            <span
              class="d-flex align-items-center justify-content-center gap-2"
              v-else-if="props.column.field === 'action'"
            >
              <div
                class="cursor-pointer"
                v-if="$can(`${$route.meta.permission}.edit`)"
                @click="$router.push(`/api-keys/${props.row.id}/edit`)"
              >
                <feather-icon size="16" icon="Edit2Icon" />
              </div>
              <div
                v-if="$can(`${$route.meta.permission}.delete`)"
                class="cursor-pointer"
                @click="destroy(props.row.id)"
              >
                <feather-icon size="16" icon="TrashIcon" />
              </div>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-end flex-wrap">
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <div class="row">
        <div
          v-for="apiKey in modifiedApiKeys"
          :key="apiKey.id"
          class="col-md-4"
        >
          <div class="card mb-1">
            <div class="card-header flex-nowrap">
              <h4>{{ apiKey.name }}</h4>
              <!-- Actions -->
              <div class="d-flex align-items-center gap-3">
                <div
                  v-if="$can(`${$route.meta.permission}.edit`)"
                  class="cursor-pointer"
                  @click="$router.push(`/api-keys/${apiKey.id}/edit`)"
                >
                  <feather-icon size="18" icon="Edit2Icon" />
                </div>
                <div
                  v-if="$can(`${$route.meta.permission}.delete`)"
                  class="cursor-pointer"
                  @click="destroy(apiKey.id)"
                >
                  <feather-icon size="18" icon="TrashIcon" />
                </div>
              </div>
            </div>
            <div class="card-body">
              <p>
                <strong>{{ $t("Client ID") }}:</strong>
                {{ truncateString(apiKey.client_id, 50) }}
              </p>
              <p>
                <strong>{{ $t("Creation Date") }}:</strong>
                {{ apiKey.creation_date.date }}
              </p>
              <p>
                <strong>{{ $t("Status") }}:</strong>
                {{ apiKey.status ? $t("Active") : $t("Inactive") }}
              </p>
              <p>
                <strong>{{ $t("Roles") }}:</strong>
                <!-- Roles Selection -->
                <multiselect
                  v-model="apiKey.roles"
                  label="title"
                  track-by="id"
                  :options="roles"
                  :multiple="true"
                  @select="assignRoles($event, apiKey.roles, apiKey.id)"
                  @remove="removeRoles($event, apiKey.roles, apiKey.id)"
                ></multiselect>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center mt-2">
        <b-pagination
          :value="1"
          :total-rows="totalRecords"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value) => onPageChange({ currentPage: value })"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
    <!--====================================-->
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    PageHeader,
    Multiselect,
  },
  computed: {
    ...mapGetters("roles", ["roles"]),
    ...mapGetters(["showLoader"]),
    modifiedApiKeys() {
      return this.rows.map((row) => {
        return {
          ...row,
          roles: row.roles
            .map((roleId) => {
              return {
                ...(this.roles?.find((role) => role.id == roleId) ?? {}),
              };
            })
            .filter((role) => role.id),
        };
      });
    },

    columns() {
      return [
        {
          label: this.$t("Name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("Client ID"),
          field: "client_id",
          sortable: false,
        },
        {
          label: this.$t("Creation Date"),
          field: "creationDate",
          sortable: false,
        },
        {
          label: this.$t("Status"),
          field: "status",
          sortable: false,
        },
        {
          label: this.$t("Roles"),
          field: "roles",
          sortable: false,
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align white-color",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Api Keys"),
          active: true,
        },
      ];
    },
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create Api key"),
          icon: "PlusIcon",
          path: "/api-keys/create",
          permission: "installation-template.create",
        },
      };
    },
  },
  data() {
    return {
      totalRecords: 0,
      pageLength: 50,
      page: 1,
      rows: [],
      searchTerm: "",
    };
  },

  async created() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store.dispatch("roles/list", {
        limit_start: 0,
        limit_count: 100,
      });
      this.loadItems();
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },

  methods: {
    truncateString(str, maxLength) {
      if (str.length > maxLength) {
        return str.substring(0, maxLength) + "...";
      } else {
        return str;
      }
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    onPageChange(params) {
      this.page = params.currentPage;
      console.log(this.page);
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.sortType = params[0].type;
      this.sortField = params[0].field;
      this.loadItems();
    },

    // load items is what brings back the rows from server
    async loadItems() {
      let response = await this.$store.dispatch("apikeys/list", {
        limit_start: this.pageLength * (this.page - 1),
        limit_count: this.pageLength,
      });
      this.rows = response?.data?.data;
      this.totalRecords = response?.data?.count;
    },
    async assignRoles(event, roles, id) {
      let arr = [];
      arr.push(event.id);
      roles.forEach((role) => {
        arr.push(role.id);
      });
      const payload = {
        roles: arr,
      };
      await this.$store.dispatch("apikeys/update", {
        id: id,
        roles: payload.roles,
      });
    },
    async removeRoles(event, roles, id) {
      let arr = [];
      roles.forEach((role) => {
        arr.push(role.id);
      });
      arr.pop(event.id);
      const payload = {
        roles: arr,
      };
      await this.$store.dispatch("apikeys/update", {
        id: id,
        roles: payload.roles,
      });
    },
    async destroy(id) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete this record?"),
        subText: this.$t("Yes delete it!"),
      });

      if (confirmed) {
        try {
          this.$store.commit("showLoader", true);
          await this.$store.dispatch("apikeys/destroy", {
            id: id,
          });
          this.loadItems();
        } catch (e) {
          console.error("api error ___", e);
        } finally {
          this.$store.commit("showLoader", false);
        }
      }
    },
  },
};
</script>

<style>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
